import type { RichText } from "pages/editor/schema/defs/version_1";
import { randId } from "sbelt/id";
import { readable } from "svelte/store";

// currentUser store

// We use a readable store here, because in the future we may want to update the caps with a push from the server
// Which makes it very useful to use the if($currentUser.caps.custom_background) pattern instead of a regular
// object

let _userMeta: UserMeta | undefined = undefined;

export type CurrentUser = {
  name: string;
  deviceId: string;
  email: string;
  id: string;
  picture: string;
  profile_url: string;
  bio?: RichText;
  caps: ProCapsCollection;
  isPaid: boolean;
  canSeeUpgradeButton: boolean;
  isInsideConnect: boolean;
  isOrgArchived: boolean;
  plan: Plan;
  licenseType: "education" | "business" | "nonprofit";
  role: "owner" | "admin" | "member";
};

export type Plan = {
  title: string;
  alternativeTitle: string;
};

export class CurrentUserError extends Error {}

export let currentUserId: string | undefined;
export let currentDeviceId: string | undefined;

// For now we're using a temp user
export function getCurrentUser() {
  const userMetadata = loadUserFromMeta();
  if (userMetadata.isLoggedIn) {
    const u = userMetadata.user!;
    currentDeviceId = u.deviceId;
    currentUserId = u.id;
    return readable<CurrentUser>(u, (set) => {
      // IN the future we can subscribe to changes here etc.
    });
  }
  throw new CurrentUserError(`User isn't logged in!`);
}

export const isLoggedIn = readable<boolean>(loadUserFromMeta().isLoggedIn, (set) => {
  // IN the future we can subscribe to changes here etc.
});

/**
 * CSRF Token
 */
export const _token = readable<string>(loadUserFromMeta()._token);

type ProCapsCollection = {
  [P in keyof ProCaps]?: ProCaps[P];
};

export type ProCaps = {
  custom_newsletter_headers: boolean;
  custom_backgrounds: boolean;
  pro_fonts: boolean;
  // pro backgrounds
  education_backgrounds: boolean;
  // pro colors
  custom_swatches: boolean;
  file_attachments: boolean;
  magic_link: boolean;
  size_limit_in_mb_per_attachment: number;
  saml_management: boolean;
  verified_sender_domain: boolean;
  newsletter_logo: boolean;
  groups: boolean;
  ignore_email_domain_for_invites: boolean;
  realtime_collaboration: boolean;
  print_download_jpeg: boolean;
  print_no_branding: boolean;
  print_high_quality: boolean;
  enhance_debugging_duplicate_newsletter: boolean;
  disable_confirmation_in_newsletter_card_overlay: boolean;
  flyer_ada_bar: boolean;
  youtube_search: boolean;
  newsletter_table_of_content: boolean;
  flyer_translation: boolean;
  feature__force_swift_12k_dialect: boolean;
  default_flyer_privacy: boolean;
  poll: boolean;
  emails_per_month: number;
  export_mns_to_customer_io: boolean;
  export_to_mass_notification_system: boolean;
  disable_auto_linkify_in_rich_text_editor: boolean;
};

export type ProCapName = `${keyof ProCaps}`;

function fromMeta<T extends {} = {}>(metaTagId: string, options: { type: "json" | "string" } = { type: "json" }): T | undefined {
  // in case we're rendering in a node process
  // in the future we should support set it up from an external property
  if (import.meta.env.SSR) {
    return undefined;
  }

  const m = document.getElementById(metaTagId);
  if (!m || !m.hasAttribute("value")) {
    return;
  }

  const value = m.getAttribute("value")!;
  return options.type === "json" ? JSON.parse(value) : value;
}

type UserMeta = {
  isLoggedIn: boolean;
  user?: CurrentUser;
  /**
   * CSRF Token
   */
  _token?: string;
};

function loadUserFromMeta(): UserMeta {
  if (!_userMeta) {
    const is_paid = fromMeta<boolean>("smore_is_paid")!;
    const can_see_upgrade_buttons = fromMeta<boolean>("can_see_upgrade_buttons")!;
    const user_is_inside_connect = fromMeta<boolean>("user_is_inside_connect")!;
    const user_info = fromMeta<Omit<CurrentUser, "caps">>("smore_user_info")!;
    const caps = fromMeta<ProCapsCollection>("smore_pro_caps")!;
    const isLoggedIn = !!user_info;
    const csrfToken = fromMeta<string>("_token", { type: "string" });

    _userMeta = {
      isLoggedIn,
      user: isLoggedIn
        ? {
            ...user_info,
            caps,
            deviceId: deviceId(),
            isPaid: is_paid,
            canSeeUpgradeButton: can_see_upgrade_buttons,
            isInsideConnect: user_is_inside_connect
          }
        : undefined,
      _token: csrfToken
    };
  }

  return _userMeta;
}

function deviceId() {
  if (import.meta.env.SSR) {
    return "-1";
  }
  const id = sessionStorage.getItem("__deviceId") || randId("d");
  sessionStorage.setItem("__deviceId", id);
  return id;
}
